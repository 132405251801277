.handsontable td {
    overflow: unset !important;
    border-color: var(--border-color) !important;
}
.handsontable th {
    background-color: var(--stepper-background) !important;
    border-color: var(--border-color) !important;
}
.htCore thead tr th {
    padding: 0.3rem !important;
    vertical-align: middle !important;
}
.custom-cell {
    text-align: center;
    vertical-align: middle !important;
    padding: 5px !important;
}
.cell-value {
    max-width: 70%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.index-cell {
    text-align: center;
    vertical-align: middle !important;
    background-color: #f0f0f0 !important;
    color: #222 !important;
    padding: 5px !important;
}
.check-box {
    margin: 0 auto;
    cursor: pointer;
    vertical-align: middle;
}
.check-cell {
    height: 24px !important;
    cursor: pointer;
    text-align: center;
    padding: 0.5rem !important;
}
.check-cell .check-box {
    width: 15px;
    height: 15px;
}
.check-all-cell {
    cursor: pointer;
    text-align: center;
    padding: 0.3rem !important;
}
.check-all-cell .check-box {
    width: 15px;
    height: 15px;
}
.checkbox {
    display: inline-flex;
    position: relative;
    vertical-align: middle;
}
  
.checkbox__control {
    -webkit-appearance: none;
    appearance: none;
    z-index: 1;
    border: 0.5px solid #51cf66;
    width: 15px;
    height: 15px;
    border-radius: 0.25rem;
    margin: 0;
    cursor: pointer;
    background-color: #51cf66;
}

.checkbox__control-icon {
    position: absolute;
    width: 15px;
    height: 15px;
    transition: opacity 400ms ease-in-out;
    opacity: 0;
    z-index: 2;
    color: #fff;
    border-radius: 0.25rem;
}

.checkbox__control:checked + .checkbox__control-icon {
    opacity: 1;
}

.list-wrapper {
    width: auto;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
    background-color: #fff;
    visibility: hidden;
    z-index: 300;
    position: absolute;
}
.list-wrapper.open {
    visibility: visible;
    position: absolute;
    width: auto;
    min-width: fit-content;
    max-width: 100%;
    z-index: 300;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    background: white;
    overflow: hidden;
    box-sizing: border-box;
    top: 100%;
    left: 0;
    margin: 0;
}


.list-wrapper ul {
    position: fixed;
    height: 180px;
    width: 191px;
    background: white;
    overflow: auto;
    margin-bottom: -20px;
    border: 1px solid #ddd;
}

.list-wrapper.open ul {
    width: 100%;
    max-width: none;
    height: auto;
    max-height: 50vh;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
}

.list-wrapper:hover .option:not(:hover) {
    background-color: transparent !important;
}

.list-dropdown {
    max-height: 250px;
    overflow: auto;
    z-index: 999;
    top: 100%;
    left: 0;
    border: 1px solid #ddd;
    width: 100%;
}
/* .ht_clone_left {
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
} */

  
.dd-searchbox {
    padding: 0.5rem;
    background-color: #fff;
    color: #000;
    border: none;
    outline: none;
    width: 100%;
    min-width: min-content;
    max-width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
}

.option {
    cursor: pointer;
    outline: none;
    padding: 0.5rem;
    list-style: none;
    width: 100%;
}

.option:hover,
.option:focus {
    background-color: #d3d3d3 !important;
}

.option.selected {
    overflow: hidden;
    width: 100%;
    font-weight: 600;
    background-color: #f6f6f6 !important;
}
.input-wrapper {
    /* max-width: 100%; */
    width: inherit;
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    gap: 2px;
    max-width: fit-content;
    box-sizing: border-box;
    position: relative;
}
.item {
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    padding: 0.0225rem 0.15rem;
    background-color: #e7eaee;
    border: 1px solid #dbdfe6;
}
.item svg {
    height: 20px;
    width: 20px;
    transition: background-color 400ms ease-in-out;
}
.item:hover svg {
    color: red;
}
.items {
    gap: 2px;
    display: flex;
    flex-wrap: wrap;
}
li.option.highlighted {
    background-color: #d3d3d3 !important;
}